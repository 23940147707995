.title {
    padding: 14px 24px;
}

.actions {
    padding: 12px 24px;

    & > :not(:first-of-type) {
        margin-left: 16px;
    }
}

.divider {
    margin: 0 10px;
}

.content {
    width: 530px;
    padding: 20px 40px;
}

.hint {
    margin-bottom: 32px;
    max-width: 400px;
}

.successContent {
    width: 530px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 44px 40px 50px;
}
