@import '../../../../../../common/style';

.wrapper {
    display: flex;
}

.titleWrapper {
    flex: 1 1 auto;
}

.title {
    @include ellipsis;
    margin-right: auto;
}
