.spinner {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 8px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
