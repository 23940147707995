.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 11px 16px 16px;
}

.dialogRoot {
    position: absolute;
}

.dialogPaperRoot {
    margin: 10px;
    max-height: 236px;
}

.date {
    display: flex;
    gap: 8px;
    align-items: center;
}

.dayDropdown {
    max-height: 180px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.buttonsLeft {
    display: flex;
    gap: 8px;
}

.buttonsRight {
    display: flex;
    gap: 8px;
}

.deleteButton {
    align-items: center;
    font-size: 13px;
    font-weight: 400;
}