@import '../sizes.scss';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: white;
}

.animation {
    height: 130px;
    width: 130px;
}
