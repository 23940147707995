.wrapper {
    display: flex;

    & > * {
        margin-inline-end: 4px;

        &:last-of-type {
            margin-inline-end: 0;
        }
    }
}

.iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    background: none;
    border: none;
    padding: 2px;
    color: #5e6a7d;
    transition: 0.3s ease-in-out all;

    &:hover {
        cursor: pointer;
        color: #d9d9d9;
        transform: scale(1.2);
    }
}
