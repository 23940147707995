.wrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}
