@keyframes opacityOnAndOff {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.directAnswer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 15px;
}

.collapsed {
    height: 6.5em;
}

.answer {
    padding: 0 15px 0 28px;
    font-size: 13px;
}

.actions {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
}

.cursor {
    animation-name: opacityOnAndOff;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.collapse {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    cursor: pointer;
    color: #61a6fa;
    white-space: nowrap;
    margin-inline-start: 5px;
}
