@import '../../../../../../common/colors.scss';

$button-size: 30px;

.wrapper {
    display: flex;
}

.title {
    flex: 1 1 auto;
}
