@import '../../../../../../common/style';

.gridItem {
    position: relative;
    text-align: center;
    border: 0.5px solid #D9D9D9;
    border-radius: 4px;
    padding: 12px;
}

.tooltipWrapper {
    position: absolute;
    top: 4px;
    right: 4px;
}

.key {
    @include ellipsis();

    padding: 0 15px;
    font-weight: 500;
    font-size: 11px;
}

.value {
    @include ellipsis();
    font-weight: 400;
    font-size: 19px;
}
