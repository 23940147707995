.wrapper {
  cursor: pointer;
  display: flex;
  gap: 7px;
  border-radius: 4px;
  padding: 4px;
  flex-basis: 170px;
}

.active {
  background-color: #E7F6FF;
}

.text {
  font-size: 12px;
  font-weight: 500;
}

.filterItem {
  font-size: 13px;
  font-weight: 400;
}

