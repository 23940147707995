@use 'sass:math';
@import 'colors';

.logoWrapper {
    text-align: center;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin space($space) {
    & > * {
        margin-inline-end: $space;

        &:last-child {
            margin-inline-end: 0;
        }
    }
}

@mixin space-vertical($space) {
    & > * {
        margin-bottom: $space;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
