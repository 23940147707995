.wrapper {
    padding: 10px 20px;
}

.widgetContent {
    position: relative;
    margin-top: 15px;
}

.directAnswer {
    padding: 0 15px 0 28px;
}

.actions {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
}
