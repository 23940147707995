@import '../../../common/style';

.header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 2px 4px rgba(24, 39, 75, 0.12), 0 4px 4px rgba(24, 39, 75, 0.08);
    margin-bottom: 18px;
}

.title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 2px;
}

.collapseAll {
    display: flex;
    align-items: center;
}
