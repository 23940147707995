@import '../../../../../common/style';

.wrapper {
    display: flex;
    flex-direction: column;
}

.header {
    flex: 0 0 20px;
    display: flex;
    align-items: center;
}

.collapse {
    @include space(10px);

    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
}

.title {
    flex: 1 1 auto;
}

.arrow {
    transform: rotate(270deg);
}

.opened {
    transform: rotate(0deg);
}

.body {
    flex: 1 1 auto;
}
