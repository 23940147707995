$chart-height: 200px;

.wrapper {
    padding: 10px 20px;
    position: relative;
}

.loader {
    height: $chart-height;
    background-position: center -100px;
    background-size: cover;
    background-repeat: no-repeat;
}

.chart {
    margin-top: 15px;
    min-height: $chart-height;
    height: $chart-height;
}

.tooltipContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    overflow-x: hidden;
}

.eventCount {
    font-size: 12px;
    font-weight: 500;
    color: #404b5c;
}

.eventDescription {
    font-size: 10px;
    color: #2187FF;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
